.bs_post_edit_row {
  display: flex;
  padding: 4px 0;
}
.bs_post_edit_row > div {
  display: inline-block;
  vertical-align: middle;
}
.bs_post_edit_row > div.bs_post_edit_row_fill_width {
  display: flex;
  flex: 1 1;
}
.bs_post_edit_row_label {
  min-width: 70px;
  margin-right: 10px;
}
.bs_post_edit_row_label.bs_post_edit_row_label_flavor {
  min-width: 80px;
}
.bs_post_edit_row_label.bs_post_edit_row_label_row {
  margin-top: 8px;
  display: flex;
}
.bs_post_edit_row_label input[type='checkbox'] {
  margin: 0 5px;
}
.bs_post_edit_row_label label {
  font-size: 0.8em;
}
.bs_post_edit_row input {
  flex: 1;
  min-width: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
}
.bs_post_edit_row .bs_post_edit_form_selectable {
  flex: 1;
  min-width: 0;
  height: 2em;
}
.bs_post_edit_row_indented {
  margin-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.bs_post_edit_col-2 {
  width: 50%;
  height: 30px;
  padding-right: 20px;
}
.bs_post_edit_col-2:last-child {
  padding-right: 0;
}
@media only screen and (max-width: 740px) {
  .bs_post_edit_row.bs_post_edit_row_mobile {
    display: block;
  }
  .bs_post_edit_row_mobile .bs_post_edit_col-2 {
    margin: 4px 0;
  }
  .bs_post_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .bs_post_edit_row.bs_post_edit_row_mobile_sm {
    display: block;
  }
  .bs_post_edit_row_mobile_sm .bs_post_edit_col-2 {
    margin: 4px 0;
  }
  .bs_post_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}

.bs_post_edit_info_sub {
  margin-left: 90px;
  margin-top: 5px;
  padding: 0px;
}
.bs_post_edit_info_sub > .bs_post_edit_row {
  padding-bottom: 0px;
}
.bs_post_edit_row_btn_edit {
  padding: 1px 3px;
  margin-bottom: 3px;
}
.bs_post_edit_btn {
  margin: 10px 10px 0px 10px;
  min-height: 50px;
}
.bs_post_edit_btn:first-child {
  margin-left: 0px;
}
.bs_post_edit_btn:last-child {
  margin-right: 0px;
}
.bs_post_edit_row_label i {
  font-size: 0.75em;
  color: gray;
  margin: 0 3px;
}
.bs_post_edit_rating_label .bs_rate_flavor {
  font-size: 1.5em;
  line-height: 1.6em;
}

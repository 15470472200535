.image-gallery-slide {
  height: 200px;
}
.image-gallery-image {
  text-align: center;
}
.image-gallery-image > img {
  width: auto;
  height: 200px;
  max-width: 100%;
}

.image-gallery-image > img[cw='90'] {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
  max-width: 200px;
  height: auto;
}
.image-gallery-image > img[cw='180'] {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.image-gallery-image > img[cw='270'] {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
  -o-transform: rotate(270deg); /* Opera */
  max-width: 200px;
  height: auto;
}
.image-gallery-bullets .image-gallery-bullet {
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 0 #999999;
  background: #c0c0c0;
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #ffc107;
}

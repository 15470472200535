body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(245, 245, 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bs_text_sub {
  font-size: 0.85em;
}
.bs_text_sub_gray {
  font-size: 0.85em;
  color: gray;
}
.bs_text_gray {
  color: gray;
}
.bs_text_primary {
  color: #ffc107;
}
.bs_clickable {
  cursor: pointer;
}
.bs_hover_gray:hover {
  background-color: #dfdfdf;
}
.bs_main_contents {
  padding-top: 42px;
  padding-bottom: 42px;
}
.bs_main_contents.bs_app {
  padding-top: 0px;
}

.bs_display_none {
  display: none !important;
}
.bs_margin_auto {
  display: block;
  margin: auto;
}

.bs_main_contents > .bs_post_item {
  width: 80%;
  margin: 20px auto;
}
.bs_rate_flavor {
  color: #ffc107;
}
.bs_radar_chart_flavors g:first-child > text {
  display: none;
}
input:disabled {
  background-color: #e3e3e3;
}
/* Alert */
.alert {
  margin-bottom: 0px;
  color: white;
}
[data-notify='container'] .alert-success {
  background-color: #3fa33f;
  opacity: 1;
}
[data-notify='container'] .alert-info {
  background-color: #ffc107;
  opacity: 1;
}
[data-notify='container'] .alert-warning {
  background-color: #ffc107;
  opacity: 1;
}
[data-notify='container'] .alert-danger {
  background-color: #bd1b31;
  opacity: 1;
}
[data-notify='container'] .close {
  color: white;
  opacity: 1;
  &:not(:disabled):not(.disabled) {
    @include hover-focus {
      color: white;
      text-decoration: none;
      opacity: 1;
    }
  }
}

/* Card */
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1em;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }
}

/* Modal */
body > div[role='dialog'],
body > div[role='dialog']:not(.modal) {
  position: relative;
  /* z-index: 1; */
  height: 0;
  overflow: visible;
}
.modal-dialog {
  top: 30%;
  transform: translateY(-50%);
}
.bs_modal_md {
  max-width: 80%;
  margin: auto;
}
.bs_modal_xsm {
  width: 300px;
  max-width: 80%;
  margin: auto;
}
.bs_loading_modal > .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
}
.bs_modal_info {
  width: 80%;
  max-width: 500px;
  margin: auto;
}

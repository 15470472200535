.bs_modal_title {
  display: flex;
}
.bs_modal_content {
  border-top: 1px solid #999999;
  padding-bottom: 40px;
}
.bs_modal_add_layout_brewery_add {
  margin-top: 10px;
}
.bs_modal_add_layout_brewery {
  margin-top: 20px;
}

.bs_modal_edit_row {
  display: flex;
  padding: 4px 0;
}
.bs_modal_edit_row_indented {
  margin-left: 10px;
}
.bs_modal_edit_row > div {
  display: inline-block;
  vertical-align: middle;
}
.bs_modal_edit_row > div.bs_modal_edit_row_fill_width {
  display: flex;
  flex: 1 1;
}
.bs_modal_edit_row_label {
  min-width: 100px;
  margin-right: 10px;
}
.bs_modal_edit_row_label.bs_modal_edit_row_label_row {
  margin-top: 8px;
  display: flex;
}
.bs_modal_edit_row_label input[type='checkbox'] {
  margin: 0 5px;
}
.bs_modal_edit_row_label label {
  font-size: 0.8em;
}
.bs_modal_edit_row input {
  flex: 1;
  min-width: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
}

.bs_modal_edit_col-2 {
  width: 50%;
  height: 30px;
  padding-right: 20px;
}
.bs_modal_edit_col-2:last-child {
  padding-right: 0;
}
@media only screen and (max-width: 740px) {
  .bs_modal_edit_row.bs_modal_edit_row_mobile {
    display: block;
  }
  .bs_modal_edit_row_mobile .bs_modal_edit_col-2 {
    margin: 4px 0;
  }
  .bs_modal_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .bs_modal_edit_row.bs_modal_edit_row_mobile_sm {
    display: block;
  }
  .bs_modal_edit_row_mobile_sm .bs_modal_edit_col-2 {
    margin: 4px 0;
  }
  .bs_modal_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}

.bs_modal_edit_row .bs_modal_edit_form_selectable {
  flex: 1;
  min-width: 0;
  height: 2.5em;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(245, 245, 245);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.bs_text_sub {
  font-size: 0.85em;
}
.bs_text_sub_gray {
  font-size: 0.85em;
  color: gray;
}
.bs_text_gray {
  color: gray;
}
.bs_text_primary {
  color: #ffc107;
}
.bs_clickable {
  cursor: pointer;
}
.bs_hover_gray:hover {
  background-color: #dfdfdf;
}
.bs_main_contents {
  padding-top: 42px;
  padding-bottom: 42px;
}
.bs_main_contents.bs_app {
  padding-top: 0px;
}

.bs_display_none {
  display: none !important;
}
.bs_margin_auto {
  display: block;
  margin: auto;
}

.bs_main_contents > .bs_post_item {
  width: 80%;
  margin: 20px auto;
}
.bs_rate_flavor {
  color: #ffc107;
}
.bs_radar_chart_flavors g:first-child > text {
  display: none;
}
input:disabled {
  background-color: #e3e3e3;
}
/* Alert */
.alert {
  margin-bottom: 0px;
  color: white;
}
[data-notify='container'] .alert-success {
  background-color: #3fa33f;
  opacity: 1;
}
[data-notify='container'] .alert-info {
  background-color: #ffc107;
  opacity: 1;
}
[data-notify='container'] .alert-warning {
  background-color: #ffc107;
  opacity: 1;
}
[data-notify='container'] .alert-danger {
  background-color: #bd1b31;
  opacity: 1;
}
[data-notify='container'] .close {
  color: white;
  opacity: 1;
  &:not(:disabled):not(.disabled) {
    @include hover-focus {
      color: white;
      text-decoration: none;
      opacity: 1;
    }
  }
}

/* Card */
.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: white;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 1em;

  > hr {
    margin-right: 0;
    margin-left: 0;
  }
}

/* Modal */
body > div[role='dialog'],
body > div[role='dialog']:not(.modal) {
  position: relative;
  /* z-index: 1; */
  height: 0;
  overflow: visible;
}
.modal-dialog {
  top: 30%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.bs_modal_md {
  max-width: 80%;
  margin: auto;
}
.bs_modal_xsm {
  width: 300px;
  max-width: 80%;
  margin: auto;
}
.bs_loading_modal > .modal-content {
  background: transparent;
  border: none;
  box-shadow: none;
  text-align: center;
}
.bs_modal_info {
  width: 80%;
  max-width: 500px;
  margin: auto;
}

.image-gallery-slide {
  height: 200px;
}
.image-gallery-image {
  text-align: center;
}
.image-gallery-image > img {
  width: auto;
  height: 200px;
  max-width: 100%;
}

.image-gallery-image > img[cw='90'] {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
  max-width: 200px;
  height: auto;
}
.image-gallery-image > img[cw='180'] {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.image-gallery-image > img[cw='270'] {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
  -o-transform: rotate(270deg); /* Opera */
  max-width: 200px;
  height: auto;
}
.image-gallery-bullets .image-gallery-bullet {
  width: 8px;
  height: 8px;
  box-shadow: 0 1px 0 #999999;
  background: #c0c0c0;
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: #ffc107;
}

.bs_post_edit_row {
  display: -webkit-flex;
  display: flex;
  padding: 4px 0;
}
.bs_post_edit_row > div {
  display: inline-block;
  vertical-align: middle;
}
.bs_post_edit_row > div.bs_post_edit_row_fill_width {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.bs_post_edit_row_label {
  min-width: 70px;
  margin-right: 10px;
}
.bs_post_edit_row_label.bs_post_edit_row_label_flavor {
  min-width: 80px;
}
.bs_post_edit_row_label.bs_post_edit_row_label_row {
  margin-top: 8px;
  display: -webkit-flex;
  display: flex;
}
.bs_post_edit_row_label input[type='checkbox'] {
  margin: 0 5px;
}
.bs_post_edit_row_label label {
  font-size: 0.8em;
}
.bs_post_edit_row input {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
}
.bs_post_edit_row .bs_post_edit_form_selectable {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 0;
  height: 2em;
}
.bs_post_edit_row_indented {
  margin-left: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
.bs_post_edit_col-2 {
  width: 50%;
  height: 30px;
  padding-right: 20px;
}
.bs_post_edit_col-2:last-child {
  padding-right: 0;
}
@media only screen and (max-width: 740px) {
  .bs_post_edit_row.bs_post_edit_row_mobile {
    display: block;
  }
  .bs_post_edit_row_mobile .bs_post_edit_col-2 {
    margin: 4px 0;
  }
  .bs_post_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .bs_post_edit_row.bs_post_edit_row_mobile_sm {
    display: block;
  }
  .bs_post_edit_row_mobile_sm .bs_post_edit_col-2 {
    margin: 4px 0;
  }
  .bs_post_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}

.bs_post_edit_info_sub {
  margin-left: 90px;
  margin-top: 5px;
  padding: 0px;
}
.bs_post_edit_info_sub > .bs_post_edit_row {
  padding-bottom: 0px;
}
.bs_post_edit_row_btn_edit {
  padding: 1px 3px;
  margin-bottom: 3px;
}
.bs_post_edit_btn {
  margin: 10px 10px 0px 10px;
  min-height: 50px;
}
.bs_post_edit_btn:first-child {
  margin-left: 0px;
}
.bs_post_edit_btn:last-child {
  margin-right: 0px;
}
.bs_post_edit_row_label i {
  font-size: 0.75em;
  color: gray;
  margin: 0 3px;
}
.bs_post_edit_rating_label .bs_rate_flavor {
  font-size: 1.5em;
  line-height: 1.6em;
}

/* .bs_image_uploader {
  width: 100%;
  overflow-x: auto;
} */
.bs_image_uploader > .fileContainer {
  box-shadow: none;
  padding: 0;
  margin: 0;
  -webkit-align-items: normal;
          align-items: normal;
}
.bs_image_uploader > .fileContainer .uploadPicturesWrapper > div:first-child {
  -webkit-justify-content: normal !important;
          justify-content: normal !important;
  max-height: 120px;
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
  overflow-x: auto;
}
.bs_image_uploader > .fileContainer .uploadPicturesWrapper .deleteImage {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  right: 2px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 20px;
  background-color: #edb202;
  top: 2px;
}
.bs_image_uploader
  > .fileContainer
  .uploadPicturesWrapper
  .deleteImage.rotateImage {
  top: 75px;
}
.uploadPictureContainer[cw='90'] > .uploadPicture {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
}
.uploadPictureContainer[cw='180'] > .uploadPicture {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.uploadPictureContainer[cw='270'] > .uploadPicture {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
  -o-transform: rotate(270deg); /* Opera */
}
.bs_image_uploader > .fileContainer .uploadPictureContainer {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bs_image_uploader > .fileContainer .uploadPictureContainer > img {
  width: auto;
  max-width: 90px;
  max-height: 100%;
}
.bs_image_uploader > .fileContainer .chooseFileButton {
  margin: 0;
  width: 120px;
  background-color: #edb202;
}
.bs_image_uploader > .fileContainer .chooseFileButton.disabled {
  background-color: gray;
  pointer-events: none;
}

.image-upload-preview {
  width: auto;
  max-width: 90px;
  max-height: 100%;
}
.image-upload-preview[cw='90'] {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
}
.image-upload-preview[cw='180'] {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.image-upload-preview[cw='270'] {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
  -o-transform: rotate(270deg); /* Opera */
}

.bs_modal_title {
  display: -webkit-flex;
  display: flex;
}
.bs_modal_content {
  border-top: 1px solid #999999;
  padding-bottom: 40px;
}
.bs_modal_add_layout_brewery_add {
  margin-top: 10px;
}
.bs_modal_add_layout_brewery {
  margin-top: 20px;
}

.bs_modal_edit_row {
  display: -webkit-flex;
  display: flex;
  padding: 4px 0;
}
.bs_modal_edit_row_indented {
  margin-left: 10px;
}
.bs_modal_edit_row > div {
  display: inline-block;
  vertical-align: middle;
}
.bs_modal_edit_row > div.bs_modal_edit_row_fill_width {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.bs_modal_edit_row_label {
  min-width: 100px;
  margin-right: 10px;
}
.bs_modal_edit_row_label.bs_modal_edit_row_label_row {
  margin-top: 8px;
  display: -webkit-flex;
  display: flex;
}
.bs_modal_edit_row_label input[type='checkbox'] {
  margin: 0 5px;
}
.bs_modal_edit_row_label label {
  font-size: 0.8em;
}
.bs_modal_edit_row input {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 0;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
}

.bs_modal_edit_col-2 {
  width: 50%;
  height: 30px;
  padding-right: 20px;
}
.bs_modal_edit_col-2:last-child {
  padding-right: 0;
}
@media only screen and (max-width: 740px) {
  .bs_modal_edit_row.bs_modal_edit_row_mobile {
    display: block;
  }
  .bs_modal_edit_row_mobile .bs_modal_edit_col-2 {
    margin: 4px 0;
  }
  .bs_modal_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600px) {
  .bs_modal_edit_row.bs_modal_edit_row_mobile_sm {
    display: block;
  }
  .bs_modal_edit_row_mobile_sm .bs_modal_edit_col-2 {
    margin: 4px 0;
  }
  .bs_modal_edit_col-2 {
    width: 100%;
    height: 30px;
    padding-right: 0;
  }
}

.bs_modal_edit_row .bs_modal_edit_form_selectable {
  -webkit-flex: 1 1;
          flex: 1 1;
  min-width: 0;
  height: 2.5em;
}


/* .bs_image_uploader {
  width: 100%;
  overflow-x: auto;
} */
.bs_image_uploader > .fileContainer {
  box-shadow: none;
  padding: 0;
  margin: 0;
  align-items: normal;
}
.bs_image_uploader > .fileContainer .uploadPicturesWrapper > div:first-child {
  justify-content: normal !important;
  max-height: 120px;
  flex-wrap: nowrap !important;
  overflow-x: auto;
}
.bs_image_uploader > .fileContainer .uploadPicturesWrapper .deleteImage {
  position: absolute;
  border-radius: 50%;
  text-align: center;
  font-weight: bold;
  right: 2px;
  width: 20px;
  height: 20px;
  font-size: 13px;
  line-height: 20px;
  background-color: #edb202;
  top: 2px;
}
.bs_image_uploader
  > .fileContainer
  .uploadPicturesWrapper
  .deleteImage.rotateImage {
  top: 75px;
}
.uploadPictureContainer[cw='90'] > .uploadPicture {
  transform: rotate(90deg);
  -ms-transform: rotate(90deg); /* IE 9 */
  -moz-transform: rotate(90deg); /* Firefox */
  -webkit-transform: rotate(90deg); /* Safari and Chrome */
  -o-transform: rotate(90deg); /* Opera */
}
.uploadPictureContainer[cw='180'] > .uploadPicture {
  transform: rotate(180deg);
  -ms-transform: rotate(180deg); /* IE 9 */
  -moz-transform: rotate(180deg); /* Firefox */
  -webkit-transform: rotate(180deg); /* Safari and Chrome */
  -o-transform: rotate(180deg); /* Opera */
}
.uploadPictureContainer[cw='270'] > .uploadPicture {
  transform: rotate(270deg);
  -ms-transform: rotate(270deg); /* IE 9 */
  -moz-transform: rotate(270deg); /* Firefox */
  -webkit-transform: rotate(270deg); /* Safari and Chrome */
  -o-transform: rotate(270deg); /* Opera */
}
.bs_image_uploader > .fileContainer .uploadPictureContainer {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.bs_image_uploader > .fileContainer .uploadPictureContainer > img {
  width: auto;
  max-width: 90px;
  max-height: 100%;
}
.bs_image_uploader > .fileContainer .chooseFileButton {
  margin: 0;
  width: 120px;
  background-color: #edb202;
}
.bs_image_uploader > .fileContainer .chooseFileButton.disabled {
  background-color: gray;
  pointer-events: none;
}
